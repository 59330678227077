import React, { useState, useEffect, useRef } from 'react';
import './Modal.css';
import lottie from 'lottie-web';
import gif from './carregando-criacao-site.gif';
import teamWork from './teamwork-web-design.json';
import axios from 'axios';

const ModalValida = ({ isOpen, onCloseErro, onCloseSucesso, email }) => {
    const containerLottie = useRef(null);

    const [codeDigits, setCodeDigits] = useState(['', '', '', '', '', '']);
    const [shake, setShake] = useState(false);
    const inputRefs = useRef([]);

    useEffect(() => {
        if (isOpen && containerLottie.current) {
            lottie.loadAnimation({
                container: containerLottie.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: teamWork,
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const allFilled = codeDigits.every((digit) => digit !== '');
        if (allFilled) {
            const code = codeDigits.join('');
            handleValidateCode(code);
        }
    }, [codeDigits]);


    const handleValidateCode = async (code) => {
        console.error('validação:', code);

        const response = await axios.post(`https://api.imobilsitesimobiliarios.com.br/valida/email/codigo`, {
            email,
            codigo: code,
        }).then((response) => {
            console.error('sucesso na validação:', response);
            
            onCloseSucesso()
        }).catch((error) => {

            console.error('Erro na validação do código:', error);
            setShake(true);
            setTimeout(() => setShake(false), 400);
            setCodeDigits(['', '', '', '', '', '']);
            inputRefs.current[0].focus();
        });
    }

    const handleChange = (e, index) => {
        const val = e.target.value.replace(/[^0-9]/g, '');
        if (!val) {
            setCodeDigits((prev) => {
                const copy = [...prev];
                copy[index] = '';
                return copy;
            });
            return;
        }
        setCodeDigits((prev) => {
            const copy = [...prev];
            copy[index] = val.slice(-1);
            return copy;
        });
        if (index < 5 && val !== '') {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !codeDigits[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className={`modal-content-valide ${shake ? 'shake' : ''}`}>
                <button
                    className="modal-close-button"
                    onClick={onCloseErro}
                >
                    x
                </button>

                <h2 className="modal-title">Digite o Código de Confirmação</h2>
                <p className="modal-text">
                    Digite o código de confirmação que foi enviado no seu email. <br />
                    Caso não apareça na sua caixa de entrada, verifique a pasta de Spam.
                </p>

                <div className="modal-inputs-container padding-format-force">
                    {Array.from({ length: 6 }).map((_, i) => (
                        <input
                            key={i}
                            type="text"
                            maxLength={1}
                            value={codeDigits[i]}
                            onChange={(e) => handleChange(e, i)}
                            onKeyDown={(e) => handleKeyDown(e, i)}
                            ref={(el) => (inputRefs.current[i] = el)}
                            className="modal-code-input padding-format-force"
                        />
                    ))}
                </div>

                {/* Se quiser usar a animação do Lottie em algum lugar: */}
                {/* <div ref={containerLottie} className="lottie-container" /> */}
            </div>
        </div>
    );
};

export default ModalValida;
