import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import axios from 'axios'
import gif from './carregando-criacao-site.gif'
import Modal from './Modal'
import Modal2 from './Modal2'
import Modal3 from './Modal3'
import Modal4 from './Modal4'
import Swal from 'sweetalert2'
import ModalValida from './ModalValida'

import Modal5 from './Modal5'
import './Modal.css';
import { saveAs } from 'file-saver';

const Result = () => {
    return (
        <p className="success-message">Seu site foi criado com sucesso. Voce vai receber o link do mesmo e os dados para acesso no email em 3 minutos.</p>
    )
}
function removerCaracteresEspeciais(str) {
    // Transforma todas as letras maiúsculas em minúsculas
    str = str.toLowerCase()
    // Expressão regular para remover caracteres especiais, números, espaços e 'ç'
    let resultado = str.replace(/[^a-zà-ú]/g, '')
    // Limita a string resultante a 30 caracteres
    return resultado.substring(0, 30)
}

function limitarTamanho(str) {

    return str.substring(0, 50)
}

function CreateSiteForm({ props }) {
    const [result, showresult] = useState(false);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [dadosFinais, setDadosFinais] = useState({});

    const [celular, setCelular] = useState('');
    const [abrirModalValida, setAbrirModalValida] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [state, setState] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(true);
    const [modalOpen, setModalOpen] = useState(true);
    const [tipoModal, setTipoModal] = useState(0)
    const [mensagemSucesso, setMensagemSucesso] = useState('');
    const closeModal = () => {
        setTipoModal(0);
    };
    const closeModalValidaSucesso = () => {
        criarUsuario()
        setAbrirModalValida(false)
    };
    const closeModalValidaErro = () => {
        setAbrirModalValida(false)
    };
    const handleCelular = (valor) => {
        // Remove todos os caracteres não numéricos
        const apenasNumeros = valor.replace(/\D/g, '');

        // Formata enquanto o usuário digita
        let formatado = apenasNumeros;
        if (apenasNumeros.length > 2) {
            formatado = `(${apenasNumeros.slice(0, 2)}) ${apenasNumeros.slice(2, 7)}`;
        }
        if (apenasNumeros.length > 7) {
            formatado += ` - ${apenasNumeros.slice(7, 11)}`;
        }

        // Atualiza o estado com o valor formatado
        setCelular(formatado);
    };

    const handleAccept = () => {
        // Substitua 'URL_DO_SEU_PDF' pela URL real do seu arquivo PDF
        const pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/imobil-dfe19.appspot.com/o/template%2FTermos%20e%20Condi%C3%A7%C3%B5es%20de%20Uso%20-%20Imobil%20Sites%20Imobiliarios.pdf?alt=media&token=799dfde9-2435-4e01-957e-87f48b71c47b';

        // Baixa o arquivo PDF quando o botão "Aceitar" é clicado
        saveAs(pdfUrl, 'Termos e Condições de Uso - Imobil Sites Imobiliarios.pdf');
    };

    async function criarUsuario() {
        setState(true)
        setTipoModal(1)
        let cont = 1;
        let intervalId;

        intervalId = setInterval(function () {
            cont = cont + 1
            console.log('timer', cont)
            setTipoModal(cont)
        }, 7000); // 10000 milissegundos = 20 segundos

        const response = await axios
            .post(`https://api.imobilsitesimobiliarios.com.br/criar/usuario/site`, dadosFinais)
            .then((response) => {
                setNome('')
                setEmail('')
                setCelular('')
                setDadosFinais({})
                setTitulo('')
                clearInterval(intervalId); // Chama clearInterval com o identificador do intervalo
                setTipoModal(100)
                setState(false)
                setMensagemSucesso('Seu site ja foi criado com sucesso! Você vai receber um email em três minutos com o link e informações de acesso.')
                // Swal.fire({
                //     title: 'Site criado com sucesso!',
                //     text: `Você vai receber seu site e acessos no seu email em até 3 minutos.`,
                //     icon: 'success',
                // })
            })
            .catch((error) => {
                setNome('')
                setEmail('')
                setDadosFinais({})
                setTitulo('')
                setCelular('')
                setState(false)
                clearInterval(intervalId); // Chama clearInterval com o identificador do intervalo
                setTipoModal(0)
                console.log('error.response', error.response)
                Swal.fire({
                    title: 'Erro!',
                    text: error.response.data,
                    icon: 'error',
                })
            })
    }
    async function validarEmail(dados) {
        console.log('validando email')
        const response = await axios
            .post(`https://api.imobilsitesimobiliarios.com.br/valida/email`, dados)
            .then((response) => {
                console.log('validando email response')

                validaCodigoEmail(dados)
            })
            .catch((error) => {
                console.log('validando email error')
                Swal.fire({
                    title: 'Erro!',
                    text: error.response.data,
                    icon: 'error',
                })
                console.log('erroror')

            })
    }

    async function validaCodigoEmail() {
        setAbrirModalValida(true)

    }
    async function sendEmail() {
        
        var dados = {
            username_father: 'admin',
            username: nome,
            email: email,
            titulo: titulo,
            celular: celular,
            admin: 'Cliente',
        }
        setDadosFinais(dados)
        if (nome == '' || nome == null) {
            Swal.fire({
                title: 'Informe um user da empresa para seu site!',
                text: `Todos os campos devem ser informados.`,
                icon: 'error',
            })
        } else if (celular == '' || celular == null) {
            Swal.fire({
                title: 'Informe um celular válido!',
                text: `Todos os campos devem ser informados.`,
                icon: 'error',
            })
        } else if (titulo == '' || titulo == null) {
            Swal.fire({
                title: 'Informe o nome da sua empresa para seu site!',
                text: `Todos os campos devem ser informados.`,
                icon: 'error',
            })
        } else if (email == '' || email == null || (email.length > 0 & !email.includes('@'))) {
            Swal.fire({
                title: 'Informe um email válido!',
                text: `Você receberá seus acessos nesse email, por isso escolha um email válido.`,
                icon: 'error',
            })
        } else if (termsAccepted != true) {
            Swal.fire({
                title: 'Você deve aceitar os termos e condições para realizar o cadastro!',
                text: `Aceite o termos e condições para criar seu site.`,
                icon: 'error',
            })
        } else {
            validarEmail(dados)
        }
    }





    return (
        <>
            {/* <dotlottie-player src="" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></dotlottie-player> */}
            {abrirModalValida ?
                <div className="modal-overlay">
                    <div className="modal-content-valide">
                        <ModalValida
                            isOpen={abrirModalValida}
                            onCloseSucesso={closeModalValidaSucesso}
                            onCloseErro={closeModalValidaErro}

                            email={email}
                        /> </div>
                </div> :


                tipoModal > 0 ?
                    <div className="modal-overlay">
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            {tipoModal == 1 ?
                                <Modal isOpen={tipoModal == 1}>
                                </Modal> :
                                tipoModal == 2 ?
                                    <Modal2 isOpen={tipoModal == 2}>
                                    </Modal2>
                                    : tipoModal == 3 ?
                                        <Modal3 isOpen={tipoModal == 3}>
                                        </Modal3>
                                        : tipoModal == 4 ?
                                            <Modal4 isOpen={tipoModal == 4}>
                                            </Modal4>
                                            : tipoModal == 100 ?
                                                <Modal5 isOpen={tipoModal == 100} onClose={closeModal}>
                                                </Modal5>
                                                : <></>
                            }
                        </div>
                    </div>
                    : <></>}
            <div className="rn-form-group">
                <small>Esse campo não deve conter caracteres especiais, letras maiúsculas e espaçamento.</small>
                <input
                    type="text"
                    name="fullname"
                    value={nome}
                    onChange={(e) => setNome(removerCaracteresEspeciais(e.target.value))}
                    placeholder="User da Empresa (somente letras)"
                    required
                />

            </div>

            <div className="rn-form-group">
                <small>Esse campo é o título do seu site. E tem limite de 50 caracteres..</small>

                <input
                    type="text"
                    name="fullname"
                    value={titulo}
                    onChange={(e) => setTitulo(limitarTamanho(e.target.value))}
                    placeholder="Nome da Empresa"
                    required
                />

            </div>

            <div className="rn-form-group">
                <small>Esse email deve ser válido para receber seus acessos.</small>

                <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    name="email"
                    placeholder="Seu Email"
                    required
                />
            </div>


            <div className="rn-form-group">
                <small>Esse celular deve ser válido para o direcionamento de anúncios do site.</small>

                <input
                    onChange={(e) => handleCelular(e.target.value)}
                    type="celular"
                    value={celular}
                    name="celular"
                    placeholder="Celular"
                    required
                />
            </div>


            <div className="rn-form-group">
                <div className="">
                    <div className="row">
                        <div className="col-lg-1" style={{ width: '15%', marginBottom: '5px' }}>
                            <input
                                onChange={(e) => setTermsAccepted(!termsAccepted)}
                                type="checkbox"
                                value={termsAccepted}
                                style={{ width: '20px' }}
                                checked={termsAccepted}
                                required
                            />
                        </div>
                        <div className="col-lg-11" style={{ marginTop: '12px', width: '75%', marginBottom: '5px' }}>
                            <small onClick={handleAccept} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Aceito os termos e condições de uso da Imobil Site Imobiliários.</small>

                        </div>
                    </div>

                </div>
                <button
                    className="rn-button-style--2 btn-solid"
                    style={{ width: '100%' }}
                    onClick={() => sendEmail()}
                    disabled={state}
                    name="submit" id="mc-embedded-subscribe">
                    {state == false ? "Criar Site" : "Seu site esta sendo criado... Aguarde"}
                </button>
            </div>

            <div className="rn-form-group">
                {mensagemSucesso.length > 0 ? <p className="success-message">Seu site foi criado com sucesso. Voce vai receber em 3 minutos no seu email o link e os dados de acesso.</p> : null}
            </div>
        </>
    )
}
export default CreateSiteForm;
